import { getPlayerModel } from 'player/views/player-model-context';
import { SCRIPT_SCROLLING_CONTAINER_ID } from 'player/views/player-ui-components';

export class SoundbiteScrollObserver {
  private observer: IntersectionObserver;
  private targets: Element[] = [];
  private options: IntersectionObserverInit;

  constructor() {
    const root = document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);

    this.options = {
      root,
      /// how much of the target element must be visible before the callback is called
      threshold: 0.9,
    };
    this.observer = new IntersectionObserver(
      this.callback.bind(this),
      this.options
    );
  }

  observeElement(element: Element) {
    this.targets.push(element);
    this.observer.observe(element);
  }

  unobserve() {
    this.targets.forEach(sentence => {
      this.observer.unobserve(sentence);
    });
  }

  disconnect() {
    this.observer.disconnect();
  }

  callback(entries: IntersectionObserverEntry[], _: IntersectionObserver) {
    const playerModel = getPlayerModel();
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        playerModel.forceDisablePlayback = false;
      }
    });
  }
}

/* Puts the current sentence in the middle of the screen – we do this during playback */
/// this function will end up doing a lot more than just centering the sentence
/// but I'm keeping the name for now
export function scrollTo(element: HTMLElement) {
  const scriptPanel = document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);
  if (scriptPanel) {
    if (element) {
      scriptPanel.scrollTo({
        top: element.offsetTop - 72,
        behavior: 'smooth',
      });
    }
  }
}
