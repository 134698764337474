import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { HStack, VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { Link } from 'naan/primitives/text';

export const DevToolsLink = () => {
  if (AppFactory.root.userManager?.hasAdminAccess) {
    return (
      <VStack>
        <VSpacer size={4} />
        <HStack justify="end">
          <Link
            as={RouterLink}
            to="/app/profile/dev-tools"
            className="account__dev-tools-link"
          >
            Dev Tools
          </Link>
          {' - '}
          <Link as={RouterLink} to="/admin" className="account__dev-tools-link">
            Admin
          </Link>
        </HStack>
        {/* <VSpacer size={4} /> */}
      </VStack>
    );
  } else {
    return null;
  }
};
