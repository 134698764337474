import React from 'react';
import { Button } from 'naan/primitives/button';
import { PlusSmallIcon } from 'naan/icons/plus-icon';

import __ from 'core/lib/localization';
import { ClassCreationModal } from '../assignment-dialog/class-creation-modal';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { useNavigate } from 'react-router-dom';
import { classroomNavPathById } from 'app/app-helpers';

export const CreateClassButton = () => {
  const showDialog = useSwitch2(false);
  const navigate = useNavigate();
  // const { l2 } = useParams<{ l2: string }>();

  const navigateToClass = (classId: string) => {
    showDialog.off();
    navigate(classroomNavPathById(classId));
  };

  return (
    <>
      <Button
        label={__('Add a class', 'classrooms.addAClass')}
        presentation="teal"
        onClick={showDialog.on}
        data-test-id="create-class"
        size="small"
        leftIcon={<PlusSmallIcon />}
      />
      {showDialog.value ? (
        <ClassCreationModal
          onClose={showDialog.off}
          onSelectClass={navigateToClass}
        />
      ) : null}
    </>
  );
};
