import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { PointsIcon } from 'naan/icons/points-icon';
import { ProgressPie } from 'components/ui/progress-pie';
import { HSpacer } from 'naan/primitives/spacer';

const ButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '20px',
  backgroundColor: '$black-alpha-10',
  px: '$space$3',
  py: '$space$2',
  alignItems: 'center',
  '& > * + *': {
    marginLeft: '$space$2',
  },
});

const StatContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  color: '$colors$white',
  alignItems: 'center',
  textStyle: 'body-bold',
});

const ProgressPieWrapper = styled('div', {
  width: '20px',
  height: '20px',
  marginRight: '$space$1',
});

const Points = ({ points }: { points: number }) => (
  <StatContainer>
    <PointsIcon />
    <HSpacer size={0} />
    {points}
  </StatContainer>
);

const Precentage = ({ precentage }: { precentage: number }) => (
  <StatContainer>
    <ProgressPieWrapper>
      <ProgressPie current={precentage / 100.0} />
    </ProgressPieWrapper>
    {`${precentage}%`}
  </StatContainer>
);

type ProgressButtonProps = {
  progressPercentage: number;
  points: number;
};

export const ProgressButton = ({
  progressPercentage,
  points,
}: ProgressButtonProps) => (
  <ButtonContainer onClick={() => {}}>
    <Precentage precentage={progressPercentage} />
    <Points points={points} />
  </ButtonContainer>
);
