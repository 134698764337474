/* eslint-disable no-console */
import * as React from 'react';
import { styled } from 'naan/stitches.config';
import {
  ResponsiveGridLayout,
  ResponsiveNavContainer,
  ResponsiveMainContainer,
} from 'components/global-layout';

import { GlobalStyles } from 'naan/global-styles';
import { HomeIcon } from 'naan/icons/home-icon';
import { FavoriteIcon } from 'naan/icons/favorite-icon';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import { EduIcon } from 'naan/icons/edu-icon';
import {
  NavArrow,
  NavBottomAnchor,
  NavContainer,
  NavItem,
  NavLogoWrapper,
  PopoverNavHeader,
  PopoverNavTrigger,
} from 'components/ui/navigation/responsive-navigation';
import { LupaLogo } from 'components/ui/lupa-logo';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { Button } from 'naan/primitives/button';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MenuContent } from 'naan/primitives/menus/menu-content';
import { MenuItem } from 'naan/primitives/menus';
import { CouponIcon } from 'naan/icons/coupon-icon';
import { GiftIcon } from 'naan/icons/gift-icon';
import { SignOutIcon } from 'naan/icons/sign-out-icon';
import { ArrowTopRightIcon } from 'naan/icons/arrow-top-right-icon';
import { Text } from 'naan/primitives/text';

// import { ResponsiveNavToggle } from 'components/nav/responsive-nav-toggle';

export const pathname = '/main-layout';
export const navlabel = 'Layout';
export const naanReady = true;

const Box = styled('div', {});

const NavDemo = ({ isClassroom }: { isClassroom: boolean }) => {
  return (
    <NavContainer presentation={isClassroom ? 'classroom' : undefined}>
      <NavLogoWrapper>
        <LupaLogo classrooms={isClassroom} />
      </NavLogoWrapper>
      <NavItem
        active
        onClick={() => console.log('Ok')}
        icon={<HomeIcon />}
        label={'Discovery'}
      />
      <NavItem
        onClick={() => console.log('Ok')}
        icon={<FavoriteIcon />}
        label={'Favorites'}
      />
      <NavItem
        onClick={() => console.log('Ok')}
        icon={<EduIcon />}
        label={'My classes'}
      />
      <NavBottomAnchor>
        <DropdownMenu.Root>
          <PopoverNavTrigger>
            <NavItem icon={<AvatarIcon />} label={'Emily'} />
          </PopoverNavTrigger>
          <MenuContent
            sideOffset={-20}
            css={{ minWidth: '300px', marginLeft: 8 }}
          >
            <PopoverNavHeader>
              <Text textStyle="small-heading">Emily</Text>
              <Text color="textSecondary" textStyle="small-text">
                emily.surname@gmail.com
              </Text>
            </PopoverNavHeader>
            <MenuItem
              label={'Manage my account'}
              leftIcon={<AvatarIcon />}
              rightIcon={<ArrowTopRightIcon width={20} height={20} />}
              presentation="navItem"
              action={() => {
                console.log('Ok');
              }}
            />
            <MenuItem
              label={'Redeem a code'}
              leftIcon={<CouponIcon />}
              rightIcon={<ArrowTopRightIcon width={20} height={20} />}
              presentation="navItem"
              action={() => {
                console.log('Ok');
              }}
            />
            <MenuItem
              label={'Gift coupons'}
              leftIcon={<GiftIcon />}
              rightIcon={<ArrowTopRightIcon width={20} height={20} />}
              presentation="navItem"
              action={() => {
                console.log('Ok');
              }}
            />

            <MenuItem
              label={'Sign out'}
              leftIcon={<SignOutIcon />}
              presentation="navItem"
              separatorAfter={false}
              action={() => {
                console.log('Ok');
              }}
            />

            <DropdownMenu.Arrow offset={16} asChild>
              <NavArrow />
            </DropdownMenu.Arrow>
          </MenuContent>
        </DropdownMenu.Root>
      </NavBottomAnchor>
    </NavContainer>
  );
};

const ContentDemo = () => {
  return (
    <Box
      css={{
        padding: '$space$8',
        textStyle: 'body',
        maxWidth: 960,
        margin: '0 auto',
      }}
    >
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis quam
      laboriosam, assumenda tenetur maxime sapiente beatae consequatur nesciunt
      libero aspernatur officiis dignissimos rerum, totam vel? Ipsa provident ut
      recusandae eaque. Lorem ipsum dolor sit amet consectetur adipisicing elit.
      Reiciendis quam laboriosam, assumenda tenetur maxime sapiente beatae
      consequatur nesciunt libero aspernatur officiis dignissimos rerum, totam
      vel? Ipsa provident ut recusandae eaque. Lorem ipsum dolor sit amet
      consectetur adipisicing elit. Reiciendis quam laboriosam, assumenda
      tenetur maxime sapiente beatae consequatur nesciunt libero aspernatur
      officiis dignissimos rerum, totam vel? Ipsa provident ut recusandae eaque.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis quam
      laboriosam, assumenda tenetur maxime sapiente beatae consequatur nesciunt
      libero aspernatur officiis dignissimos rerum, totam vel? Ipsa provident ut
      recusandae eaque. Lorem ipsum dolor sit amet consectetur adipisicing elit.
      Reiciendis quam laboriosam, assumenda tenetur maxime sapiente beatae
      consequatur nesciunt libero aspernatur officiis dignissimos rerum, totam
      vel? Ipsa provident ut recusandae eaque. Lorem ipsum dolor sit amet
      consectetur adipisicing elit. Reiciendis quam laboriosam, assumenda
      tenetur maxime sapiente beatae consequatur nesciunt libero aspernatur
      officiis dignissimos rerum, totam vel? Ipsa provident ut recusandae eaque.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis quam
      laboriosam, assumenda tenetur maxime sapiente beatae consequatur nesciunt
      libero aspernatur officiis dignissimos rerum, totam vel? Ipsa provident ut
      recusandae eaque. Lorem ipsum dolor sit amet consectetur adipisicing elit.
      Reiciendis quam laboriosam, assumenda tenetur maxime sapiente beatae
      consequatur nesciunt libero aspernatur officiis dignissimos rerum, totam
      vel? Ipsa provident ut recusandae eaque. Lorem ipsum dolor sit amet
      consectetur adipisicing elit. Reiciendis quam laboriosam, assumenda
      tenetur maxime sapiente beatae consequatur nesciunt libero aspernatur
      officiis dignissimos rerum, totam vel? Ipsa provident ut recusandae eaque.
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis quam
      laboriosam, assumenda tenetur maxime sapiente beatae consequatur nesciunt
      libero aspernatur officiis dignissimos rerum, totam vel? Ipsa provident ut
      recusandae eaque. Lorem ipsum dolor sit amet consectetur adipisicing elit.
      Reiciendis quam laboriosam, assumenda tenetur maxime sapiente beatae
      consequatur nesciunt libero aspernatur officiis dignissimos rerum, totam
      vel? Ipsa provident ut recusandae eaque. Lorem ipsum dolor sit amet
      consectetur adipisicing elit. Reiciendis quam laboriosam, assumenda
      tenetur maxime sapiente beatae consequatur nesciunt libero aspernatur
      officiis dignissimos rerum, totam vel? Ipsa provident ut recusandae eaque.
    </Box>
  );
};

export const Screen = () => {
  const isClassroom = useSwitch2(false);
  return (
    <>
      <GlobalStyles />
      <ResponsiveGridLayout className="x-global-grid">
        <ResponsiveMainContainer>
          <Box css={{ padding: 24 }}>
            <Button
              label={isClassroom.value ? 'Classroom mode on' : 'Normal mode on'}
              presentation={isClassroom.value ? 'yellow' : 'grayLight'}
              onClick={isClassroom.toggle}
            />
          </Box>
          <ContentDemo />
        </ResponsiveMainContainer>
        <ResponsiveNavContainer>
          <NavDemo isClassroom={isClassroom.value}></NavDemo>
        </ResponsiveNavContainer>
      </ResponsiveGridLayout>
    </>
  );
};
