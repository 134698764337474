import * as React from 'react';
import { observer } from 'mobx-react';
import { Vocabulary } from './info/vocabulary';
import { ExcerptData } from '@tikka/client/catalog-types';
import { styled } from '@naan/stitches.config';
import { GlobalRedactionButton } from 'study/views/global-redaction-button';

const Wrapper = styled('div', {
  padding: '0 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  maxWidth: '600px',
  margin: '0 auto',
  position: 'relative',
  marginTop: 24,

  '& > .redaction-button': {
    position: 'absolute',
    right: '0',
  },

  '@widerThanScript': {
    justifyContent: 'center',
    marginTop: 0,
  },
});

export const SbToolbar = observer(({ excerpt }: { excerpt: ExcerptData }) => {
  return (
    <Wrapper>
      <div className="vocabulary-button">
        <Vocabulary excerpt={excerpt} />
      </div>
      <div className="redaction-button">
        <GlobalRedactionButton />
      </div>
    </Wrapper>
  );
});
