import * as React from 'react';
import { styled } from 'naan/stitches.config';

const Wrapper = styled('div', {
  $$backgroundColor: 'transparent',
  $$centerColumnGap: '$space$4',

  backgroundColor: '$$backgroundColor',
  display: 'grid',
  gridTemplateColumns:
    '1fr min($$maxWidth, calc( 100% - calc( $$centerColumnGap * 2) )) 1fr',

  '& > .inner': {
    gridColumn: '2',
    // background: 'rgba(0,0,0,.4)',
  },

  '@medium': {
    $$centerColumnGap: '$space$6',
  },
});

export const CenterColumnLayout: React.FC<{ backgroundColor?: string }> = ({
  children,
  backgroundColor = 'transparent',
}) => {
  return (
    <Wrapper
      css={{
        $$backgroundColor: backgroundColor,
      }}
    >
      <div className="inner">{children}</div>
    </Wrapper>
  );
};
